import { FC, ReactNode, createContext, useContext, useState } from "react";

// mui
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

type LoadingContextType = {
  loading: boolean;
  loadingWrapper: (func: () => Promise<void>) => void;
  setLoading: (loading: boolean) => void;
};

const LoadingContext = createContext<LoadingContextType>({
  loading: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  loadingWrapper: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLoading: () => {},
});

export const LoadingProvider = ({ children }: { children: ReactNode }) => {
  const [loading, setLoading] = useState(false);

  const loadingWrapper = async (func: () => Promise<void>) => {
    try {
      setLoading(true);
      await func();
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoadingContext.Provider
      value={{
        loading,
        loadingWrapper,
        setLoading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoading = () => useContext(LoadingContext);

export const Loading: FC = () => {
  const { loading } = useLoading();
  return (
    <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }} open={loading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
