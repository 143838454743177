import { createTheme } from "@mui/material/styles";

// Create a theme instance.
// https://mui.com/material-ui/customization/default-theme/
// https://bareynol.github.io/mui-theme-creator/
const theme = createTheme({
  palette: {
    primary: {
      main: "#085995",
    },
  },
  typography: {
    button: {
      fontWeight: 600, // Buttonの中のtextを太字にする
    },
  },
});

export default theme;
