import { useState, useEffect } from "react";
import dynamic from "next/dynamic";
import type { AppProps } from "next/app";
import { appWithTranslation } from "next-i18next";
import { Provider } from "urql";
import { ErrorBoundary } from "@appsignal/react";
import { RecoilRoot } from "recoil";
import { useRouter } from "next/router";
import { LicenseInfo } from "@mui/x-license-pro";

// custom
import { urqlClient } from "lib/graphqlClient";
import { MeFieldFragment } from "graphql/generated";
import { AnalyticsTags, usePageView } from "lib/AnalyticsTags";
import { appsignal, appsignalTags } from "lib/appsignal";
import { MeContext } from "lib/auth";
import { DictContext } from "lib/dict";
import { LoadingProvider, Loading } from "lib/loadingContext";
import JotaiProvider from "lib/jotaiProvider";
import "../styles/nitoel.css";
import theme from "lib/theme";
import createEmotionCache from "lib/createEmotionCache";
const GlobalHeader = dynamic(() => import("components/GlobalHeader"), { ssr: false });
const ChatWidgets = dynamic(() => import("components/chat/ChatWidgets"), { ssr: false });

// mui
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { CacheProvider, EmotionCache } from "@emotion/react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

type DictContentType = Record<string, unknown>;

// MUI premiumのライセンスキーを設定
LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_PREMIUM_LICENSE_KEY || "no-license-key");

const FallbackComponent = () => <div>Sorry... There was something wrong. Please reload or contact to administrator.</div>;

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
  pageProps: {
    me: MeFieldFragment;
    dict: DictContentType;
    layout: string | null;
  };
}

const MyApp: React.FC<MyAppProps> = (props) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const router = useRouter();
  usePageView(); // GA用のイベントフックを仕込む。

  // cometchatの初期化
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const CometChat = require("@cometchat-pro/chat").CometChat;
    const appSetting = new CometChat.AppSettingsBuilder()
      .subscribePresenceForAllUsers()
      .autoEstablishSocketConnection(true)
      .setRegion("us")
      .build();
    CometChat.init(process.env.NEXT_PUBLIC_COMETCHAT_APP_ID, appSetting).then(
      () => {
        console.log("init cometchat ok");
      },
      (error: any) => {
        console.log("init cometchat fail", error);
      },
    );
  }, []);

  // https://github.com/vercel/next.js/blob/canary/examples/with-loading/pages/_app.tsx
  // を参考に。画面遷移時にLoadingするようにするための処理。
  const [pageLoading, setPageLoading] = useState(false);
  useEffect(() => {
    const handleStart = () => {
      setPageLoading(true);
    };

    const handleStop = () => {
      setPageLoading(false);
    };

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleStop);
    router.events.on("routeChangeError", handleStop);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleStop);
      router.events.off("routeChangeError", handleStop);
    };
  }, [router]);

  const PageLoading: React.FC = () => {
    return (
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }} open={pageLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  let content = null;
  if (pageProps.layout === "none") {
    content = <Component {...pageProps} />;
    // ログインしてないけど、nitoel的な装飾をしたいページ用
  } else if (pageProps.layout === "noLoginHeader") {
    content = (
      <GlobalHeader noMenu>
        <PageLoading />
        <Component {...pageProps} />
      </GlobalHeader>
    );
  } else {
    content = (
      <GlobalHeader>
        <PageLoading />
        <Component {...pageProps} />
      </GlobalHeader>
    );
  }

  return (
    <ErrorBoundary
      instance={appsignal}
      tags={appsignalTags(pageProps.me, router.pathname)}
      fallback={() => <FallbackComponent />}
    >
      <Provider value={urqlClient}>
        <RecoilRoot>
          <CacheProvider value={emotionCache}>
            <ThemeProvider theme={theme}>
              <MeContext.Provider value={pageProps.me}>
                <DictContext.Provider
                  value={{ dict: pageProps.dict, replacements: {}, debug: router.query.debug_dict != undefined }}
                >
                  <JotaiProvider>
                    <CssBaseline />
                    <LoadingProvider>
                      <AnalyticsTags />
                      <Loading />
                      <ChatWidgets />
                      {content}
                    </LoadingProvider>
                  </JotaiProvider>
                </DictContext.Provider>
              </MeContext.Provider>
            </ThemeProvider>
          </CacheProvider>
        </RecoilRoot>
      </Provider>
    </ErrorBoundary>
  );
};

export default appWithTranslation(MyApp as React.FC);
