"use client";

import { Provider } from "jotai";
import { ReactNode } from "react";
import { v4 as uuidv4 } from "uuid";

export default function Providers({ children }: { children: ReactNode }) {
  const uuid = uuidv4();
  // https://github.com/Nitoel/nitoel/issues/2131
  // 超大事
  // keyをuuidにすることで
  // 1 requestにつき1Providerが生成されるようになる
  return <Provider key={uuid}>{children}</Provider>;
}
