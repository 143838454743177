// 400 Bad Requestの処理のための関数。主にSignUp時のtokenの有効期限切れなどのエラーを処理する。
export type ErrorKey = "invalid_access" | "already_operated" | "expired" | "unknown";

const handleBadRequestError = (error: string): ErrorKey => {
  // 上書きするので仮の値を入れておく
  let errorKey: ErrorKey = "unknown";
  if (error.includes("invalid access")) {
    errorKey = "invalid_access";
  } else if (error.includes("already operated")) {
    errorKey = "already_operated";
  } else if (error.includes("expired")) {
    errorKey = "expired";
  } else {
    errorKey = "unknown";
  }
  return errorKey;
};

export default handleBadRequestError;
