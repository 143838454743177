import { devtoolsExchange } from "@urql/devtools";
import { createClient, cacheExchange, fetchExchange, Exchange } from "urql";

const exchangeOptions =
  process.env.NODE_ENV == "production" ? [cacheExchange, fetchExchange] : [devtoolsExchange, cacheExchange, fetchExchange];

export const urqlClient = createClient({
  url: `${process.env.NEXT_PUBLIC_FRONTEND_API_BASE_URL}/api/graphql_proxy`,
  exchanges: exchangeOptions as Exchange[],
});
