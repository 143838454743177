// 認証関連の関数などをまとめた
// auth0を使っているわけではないが、auth0を参考にした
// https://github.com/auth0/auth0-react
// https://github.com/auth0/nextjs-auth0 => 特にこちら

// https://github.com/auth0/nextjs-auth0#comparison-with-the-auth0-react-sdk
// 要するに、ふつうのreactだとブラウザ内のcontextからしか通信が起きないのでtokenなどをブラウザのsession storage
// などに入れておけば良いけど、
// nextjsだと通信が起きるcontextが「ブラウザ内」と「サーバー(node.js)」の2つになるので、サーバにsession storageとかないので、
// cookieを使わないとできない

// nextjsにはAPIを書ける機能(https://nextjs.org/docs/api-routes/introduction)があるので、それを下記のように使っている
// TODO: ここにもうちょっと詳細に

import type { NextApiRequest, NextApiResponse, GetServerSideProps } from "next";
import { serialize, CookieSerializeOptions } from "cookie";
import { createContext, useContext } from "react";

// custom
import { MeFieldFragment } from "graphql/generated";
import buildSSRGraphqlClient from "lib/ssrGraphqlClient";
import { getSdk } from "graphql/ssr.generated";
import { xReferer, reportToAppsignal, fetchMe } from "lib/appsignal";
import { DEFAULT_LOCALE } from "lib/const";
import handleBadRequestError from "lib/handle_bad_request";

export const COOKIE_KEY = "supplier-auth";

// cookieを焼き付ける関数
// https://nextjs.org/docs/api-routes/api-middlewares
function setCookie(res: NextApiResponse, name: string, value: unknown, options: CookieSerializeOptions = {}) {
  const stringValue = typeof value === "object" ? "j:" + JSON.stringify(value) : String(value);

  if (options.maxAge) {
    options.expires = new Date(Date.now() + options.maxAge);
    options.maxAge /= 1000;
  }

  res.setHeader("Set-Cookie", serialize(name, stringValue, options));
}

// nextjs APIでログインAPIを定義する関数
async function handleLogin(req: NextApiRequest, res: NextApiResponse) {
  // TODO: 環境変数
  const apiRes = await fetch(`${process.env.NEXT_PUBLIC_BACKEND_API_BASE_URL}/supplier_users/sign_in`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "manual",
    body: JSON.stringify(req.body),
  });

  if (apiRes.ok) {
    let apiResponseBody = {};
    if (apiRes.status === 204) {
      // 200 okでotp_requiredでない場合はrailsからのレスポンスに入ってるJWT tokenをsetCookieする
      // こうすることで後続のリクエストにcookie情報がcookieの仕様上勝手に入ってくる
      const authHeader = apiRes.headers.get("Authorization");
      const jwtToken = authHeader ? authHeader.replace("Bearer ", "") : null;
      setCookie(res, COOKIE_KEY, jwtToken, {
        // jsからのcookieの取得を認めない(httpからのみ)
        httpOnly: true,
        // 同じドメインへのみcookieを送信
        sameSite: "strict",
        // 本番環境ではhttps環境じゃないとcookieを送信しない
        secure: process.env.NODE_ENV === "production" ? true : false,
        // cookieが有効となるpath
        path: "/",
      });
    } else {
      // 2FAの認証前
      // 200 okで　message に "otp_required"　のレスポンスが返る
      apiResponseBody = await apiRes.json();
    }
    res.status(200).json(apiResponseBody);
    return;
  } else {
    // それ以外なら401で返すだけ
    res.status(401).json({});
    return;
  }
}

// react側から呼び出すlogout関数
// 返り値: promise
function logout() {
  return fetch("api/auth/logout", {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

// nextjs APIでログアウトAPIを定義する関数
async function handleLogout(req: NextApiRequest, res: NextApiResponse) {
  const apiRes = await fetch(`${process.env.NEXT_PUBLIC_BACKEND_API_BASE_URL}/supplier_users/sign_out`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: req.cookies[COOKIE_KEY] || "",
    },
    redirect: "manual",
  });

  if (apiRes.ok) {
    // cookieを削除する
    // https://stackoverflow.com/questions/62101821/nextjs-api-routes-how-to-remove-a-cookie-from-header
    setCookie(res, COOKIE_KEY, "", {
      maxAge: -1,
      path: "/",
    });
    res.status(200).json({});
    return;
  } else {
    res.status(400).json({});
    return;
  }
}

function sendResetPasswordMail(email: string) {
  return fetch(`${process.env.NEXT_PUBLIC_BACKEND_API_BASE_URL}/supplier_users/password`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ supplier_user: { email } }),
  });
}

function updatePassword(token: string, password: string, passwordConfirmation: string) {
  return fetch(`${process.env.NEXT_PUBLIC_BACKEND_API_BASE_URL}/supplier_users/password`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      supplier_user: { password, password_confirmation: passwordConfirmation, reset_password_token: token },
    }),
  });
}

export const MeContext = createContext<MeFieldFragment | null>(null);
// component内で、現在ログイン中のuserを取得したいときに呼ぶ関数
// providerはpages/_app.tsxに書いてあり、初期値はpages配下のgetServerSidePropsなどが返すme props
export const useMe = () => useContext(MeContext);

// 認証が必要なページに噛ませる
// https://github.com/auth0/nextjs-auth0
// 上記auth0のnode moduleを参考にして作った
// 認証と同時にlocaleなども自動で設定してくれる
// 使用例: returnToを指定しなければ、そのページのurlが自動で入る
// export const getServerSideProps = withPageAuthRequired({
//   async getServerSideProps(ctx) {
//     return { props: { customProp: 'bar' } };
//   },
//   returnTo: '/foo',
// });
type WithPageAuthRequiredOptions = {
  getServerSideProps?: GetServerSideProps;
  returnTo?: string;
  trans?: {
    func: (locale: string, nameSpaces: string[]) => any;
    nameSpaces: string[];
  };
};

function withPageAuthRequired(options: WithPageAuthRequiredOptions): GetServerSideProps {
  const { getServerSideProps, returnTo, trans } = options;
  return async (context) => {
    try {
      const client = buildSSRGraphqlClient(context);
      const sdk = getSdk(client);
      const { me } = await sdk.Me();

      let ret: any = { props: {} };
      if (getServerSideProps) {
        ret = await getServerSideProps(context);
      }

      if (trans) {
        const locale = me?.languageCode ? me.languageCode : DEFAULT_LOCALE;
        return {
          ...ret,
          props: {
            ...ret.props,
            ...(await trans.func(locale, trans.nameSpaces)),
            me,
          },
        };
      } else {
        return {
          ...ret,
          props: {
            ...ret.props,
            me,
          },
        };
      }
    } catch (error: any) {
      // ここに来るerrorは
      //   - backendで起きたerrorをgraphqlがobjectとして返すパターン
      //   - JSの文脈で起きた純粋なError
      // が想定される。前半3個のif分岐はrails側がgraphqlErrorとして返したものを補足している。
      // 一番最後でそれ以外のerrorを補足してappsignalに通知する。
      if (error.message.startsWith("unauthorized")) {
        const msg = "セッションが切れたのでログアウトしました";
        return {
          redirect: {
            destination: `/login?redirectTo=${encodeURIComponent(
              returnTo || context.resolvedUrl,
            )}&notify_msg=${encodeURIComponent(msg)}`,
            permanent: false,
          },
        };
        // 権限系のエラー
      } else if (error.message.startsWith("permissionDeny")) {
        return {
          redirect: {
            destination: `/403?msg=${error.response?.errors[0].message}`,
            permanent: false,
          },
        };
        // token系のエラー諸々
      } else if (
        error.message.startsWith("invalid access") ||
        error.message.startsWith("already operated") ||
        error.message.startsWith("expired")
      ) {
        return {
          redirect: {
            destination: `/error/bad_request?error=${handleBadRequestError(error.response?.errors[0].message)}`,
            permanent: false,
          },
        };
      } else {
        reportToAppsignal(error as Error, await fetchMe(context.req.cookies[COOKIE_KEY]), xReferer(context.req));
        throw error;
      }
    }
  };
}

// react側から呼び出すlogin関数
async function login(email: string, password: string, otpAttempt = "") {
  const response = await fetch("api/auth/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ supplier_user: { email, password, otpAttempt } }),
  });

  const data = await response.json();
  return {
    ok: response.ok,
    data: data,
  };
}

// react側から呼び出すsignupBrandNew関数
// 返り値: promise
function signupBrandNew(
  firmName: string,
  name: string,
  email: string,
  tel: string,
  password: string,
  token: string,
  fromKind: string,
) {
  return fetch("api/auth/signup", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ supplier_user: { email, password, name, tel, firm_name: firmName, token, from_kind: fromKind } }),
  });
}

function signupConnect(token: string, name: string, email: string, tel: string, password: string, fromKind: string) {
  return fetch("api/auth/signup", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ supplier_user: { email, password, name, tel, token, from_kind: fromKind } }),
  });
}

// nextjs APIでサインアップAPIを定義する関数
async function handleSignUp(req: NextApiRequest, res: NextApiResponse) {
  // TODO: 環境変数
  const apiRes = await fetch(`${process.env.NEXT_PUBLIC_BACKEND_API_BASE_URL}/supplier_users`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "manual",
    body: JSON.stringify(req.body),
  });

  if (apiRes.ok) {
    // 200 okならrailsからのレスポンスに入ってるJWT tokenをsetCookieする
    // こうすることで後続のリクエストにcookie情報がcookieの仕様上勝手に入ってくる
    const authHeader = apiRes.headers.get("Authorization");
    const jwtToken = authHeader ? authHeader.replace("Bearer ", "") : null;
    setCookie(res, COOKIE_KEY, jwtToken, {
      // jsからのcookieの取得を認めない(httpからのみ)
      httpOnly: true,
      // 同じドメインへのみcookieを送信
      sameSite: "strict",
      // 本番環境ではhttps環境じゃないとcookieを送信しない
      secure: process.env.NODE_ENV === "production" ? true : false,
      // cookieが有効となるpath
      path: "/",
    });
    res.status(200).json({});
    return;
  } else {
    // それ以外なら401で返すだけ
    const json = await apiRes.json();
    res.status(401).json(json);
    return;
  }
}

const exportedObject = {
  login,
  handleLogin,
  logout,
  handleLogout,
  sendResetPasswordMail,
  updatePassword,
  withPageAuthRequired,
  signupBrandNew,
  signupConnect,
  handleSignUp,
};
export default exportedObject;
